<template>
  <v-container fluid class="px-6">
    <v-overlay :value="overlay"><v-progress-circular :size="70" :width="7" color="green"
        indeterminate></v-progress-circular></v-overlay>

    <v-card class="card-shadow border-radius-xl">
      <div class="ma-5">
        <!-- Style Fix -->
        <v-row>
          <v-col></v-col>
        </v-row>
        <v-row>
          <v-col cols="12" md="3">
            <label class="text-md font-weight-bolder ms-1 mandatory">GST Type</label>
            <v-select v-model="gstType" @change="typeChange" :items="gstTypes" item-text="type" item-value="type" class="
                input-style
                font-size-input
                text-light-input
                placeholder-light
                input-icon
                mt-2
              " dense flat filled solo height="43" persistent-hint>
            </v-select>
          </v-col>
          <v-col cols="12" md="4" v-show="showLgst">
            <label class="text-md font-weight-bolder ms-1 mandatory"><span class="red--text"><strong>*
                </strong></span>CGST</label>
            <v-text-field v-model.number="gst.cgst" hide-details="auto" class="
                input-style
                font-size-input
                text-light-input
                placeholder-light
                input-icon
                mt-2
              " dense flat filled solo height="43" persistent-hint :disabled="isDisable"></v-text-field></v-col>
          <v-col cols="12" md="4" v-show="showLgst"><label class="text-md font-weight-bolder ms-1 mandatory"><span
                class="red--text"><strong>* </strong></span>SGST</label>
            <v-text-field v-model.number="gst.sgst" hide-details="auto" class="
                input-style
                font-size-input
                text-light-input
                placeholder-light
                input-icon
                mt-2
              " dense flat filled solo height="43" persistent-hint :disabled="isDisable"></v-text-field></v-col><v-col
            cols="12" md="4" v-show="showIgst"><label class="text-md font-weight-bolder ms-1 mandatory"><span
                class="red--text"><strong>* </strong></span>IGST</label>
            <v-text-field v-model.number="gst.igst" hide-details="auto" class="
                input-style
                font-size-input
                text-light-input
                placeholder-light
                input-icon
                mt-2
              " dense flat filled solo height="43" persistent-hint :disabled="isDisable"></v-text-field></v-col>
          <v-col class="d-flex justify-end mx-5" v-show="showEdit"><v-btn @click="enableEdit" elevation="0"
              :ripple="false" height="43" dark v-show="isDisable" class="
                font-weight-bold
                text-capitalize
                btn-primary
                bg-success
                mt-7
              ">Edit</v-btn>

            <v-btn @click="saveGst" elevation="0" :ripple="false" height="43" dark v-show="!isDisable" class="
                font-weight-bold
                text-capitalize
                btn-primary
                bg-success
                mt-7
              ">Save</v-btn>
          </v-col>
        </v-row>
        <v-row class="mb-5 mt-5"> </v-row>
      </div>
    </v-card>

    <v-card class="card-shadow border-radius-xl">
      <v-card-text class="card-padding">
        <v-form ref="frm">
          <v-row>
            <v-col cols="12" md="3">
              <label class="text-md text-typo font-weight-bolder ms-1"><span class="red--text"><strong>*
                  </strong></span>From
                Company</label>
              <v-autocomplete @change="filterCompanyWareHouse" v-model="from_company_warehouse"
                :items="from_company_warehouses" item-text="name" item-value="id" color="rgba(0,0,0,.6)" class="
                  input-style
                  font-size-input
                  text-light-input
                  placeholder-light
                  border-radius-md
                  select-style
                  mb-0
                  mt-5
                " placeholder="Select a Company WareHouse" :rules="[(v) => !!v || 'Company WareHouse is Required']"
                outlined single-line height="46">
              </v-autocomplete>
            </v-col>
            <v-col cols="12" md="3">
              <label class="text-md text-typo font-weight-bolder ms-1"><span class="red--text"><strong>*
                  </strong></span>To
                Company</label>
              <v-autocomplete v-model="to_company_warehouse" :items="to_company_warehouses" item-text="name"
                item-value="id" color="rgba(0,0,0,.6)" class="
                  input-style
                  font-size-input
                  text-light-input
                  placeholder-light
                  border-radius-md
                  select-style
                  mb-0
                  mt-5
                " placeholder="Select a Company WareHouse" :rules="[(v) => !!v || 'Company WareHouse is Required']"
                outlined single-line height="46">
              </v-autocomplete></v-col>
          </v-row>
          <v-row>
            <v-col class="d-flex justify-end mx-5">
              <v-btn @click="clearFilter" elevation="0" :ripple="false" height="43" class="
                  font-weight-bold
                  text-capitalize
                  btn-ls btn-secondary
                  bg-light
                  py-3
                  px-6
                  mr-2
                ">Clear</v-btn>

              <v-btn elevation="0" :ripple="false" height="43" dark
                class="text-capitalize btn-ls btn-primary bg-success py-3 px-6" @click="getInventory">Search</v-btn>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
    </v-card>
    <v-card class="card-shadow border-radius-xl mt-5" v-show="showInventory">
      <v-card-text>
        <v-form ref="frmFilter">
          <v-row>
            <v-col cols="12" sm="6" md="3" xs="12" lg="3">
              <label class="text-md text-typo font-weight-bolder ms-1">Grade</label>
              <v-autocomplete @change="filterByGradeMark()" :items="grade" item-value="grade_name" item-text="grade_name"
                v-model="filter.grade" class="
                  input-style
                  font-size-input
                  text-light-input
                  placeholder-light
                  select-style
                  mt-3
                " outlined chips multiple height="46" single-line placeholder="Select Grade">
                <template v-slot:selection="{ item, index }">
                  <v-chip v-if="index === 0" label color="bg-default" class="py-1 px-2 my-0">
                    <span class="text-white text-caption ls-0">{{
                      item.grade_code
                    }}</span>
                  </v-chip>
                  <span v-if="index === 1" class="grey--text text-caption">
                    (+{{ filter.grade.length - 1 }}
                    others)
                  </span>
                </template>
              </v-autocomplete>
            </v-col>
            <v-col cols="12" sm="6" md="3" xs="12" lg="3">
              <label class="text-md text-typo font-weight-bolder ms-1">Mark</label>
              <v-autocomplete :items="mark" @change="filterByGradeMark()" item-value="mark_name" item-text="mark_name"
                v-model="filter.mark" class="
                  input-style
                  font-size-input
                  text-light-input
                  placeholder-light
                  select-style
                  mt-3
                " outlined chips multiple height="46" single-line placeholder="Select Mark">
                <template v-slot:selection="{ item, index }">
                  <v-chip v-if="index === 0" label color="bg-default" class="py-1 px-2 my-0">
                    <span class="text-white text-caption ls-0">{{
                      item.mark_code
                    }}</span>
                  </v-chip>
                  <span v-if="index === 1" class="grey--text text-caption">
                    (+{{ filter.mark.length - 1 }}
                    others)
                  </span>
                </template>
              </v-autocomplete>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
    </v-card>
    <!-- Inventory Data Table -->
    <v-card class="card-shadow border-radius-xl mt-5" v-show="showInventory">
      <v-card-text class="card-padding">
        <v-form ref="frm_table">
          <v-data-table v-model="selectedData" :headers="headers" :items="inventoryDetails" :search="filter.search"
            item-key="id" show-select mobile-breakpoint="0" fixed-header class="table" @item-selected="selected"
            disable-pagination hide-default-footer @toggle-select-all="allSelected" :footer-props="{
              'items-per-page-options': [100, 200, 500, -1],
            }">
            <template v-slot:top>
              <v-toolbar flat>
                <!-- <v-toolbar-title>Private Allocation</v-toolbar-title> -->
                <v-row>
                  <v-col cols="12" md="6" lg="6">
                    <v-text-field hide-details class="
                        input-style
                        font-size-input
                        text-light-input
                        placeholder-light
                        input-icon
                      " dense flat filled solo height="46" v-model="filter.search"
                      placeholder="Search with : Batch No / Invoice NO / Item Name">
                      <template slot="prepend-inner">
                        <v-icon color="#adb5bd" size=".875rem">fas fa-search</v-icon>
                      </template>
                    </v-text-field>
                  </v-col>
                  <v-col cols="4" md="4" lg="4">
                    <label class="text-md font-weight-bolder ms-1 mandatory">
                      Items Selected : {{ selectedData.length }}</label>
                  </v-col>
                </v-row>

                <v-btn @click="ShowSelected" elevation="0" :ripple="false" height="43" dark class="
                    font-weight-bold
                    text-capitalize
                    btn-ls btn-primary
                    bg-success
                    py-3
                    px-6
                    mb-5
                    mt-5
                  ">Show Selected
                </v-btn>
              </v-toolbar>
            </template>
          </v-data-table>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-row>
          <v-col offset-md="5">
            <v-btn @click="confirm" elevation="0" :ripple="false" height="43" dark class="
                font-weight-bold
                text-capitalize
                btn-ls btn-primary
                bg-success
                py-3
                px-6
                mb-5
                mt-5
              ">Save
            </v-btn>
          </v-col>
        </v-row>
      </v-card-actions>
    </v-card>
    <!-- Dialogue Save Confirm -->
    <v-dialog v-model="confirmDialog" max-width="500px" persistent>
      <v-card class="card-shadow card-padding border-radius-xl">
        <v-card-title class="pt-0 text-h5 text-typo justify-center">
          <p class="text-center">Do you want to Save With</p>
          <p class="text-center">{{ message }}?</p>
        </v-card-title>
        <v-card-actions class="pb-0">
          <v-spacer></v-spacer>
          <v-btn @click="confirmCancel" elevation="0" :ripple="false" height="43"
            class="font-weight-bold text-capitalize btn-ls bg-light py-3 px-6">No</v-btn>

          <v-btn @click="confirmOk" elevation="0" :ripple="false" height="43" class="
              font-weight-bold
              text-capitalize
              btn-ls btn-primary
              bg-success
              py-3
              px-6
            ">Yes</v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- Show Batch No -->
    <v-dialog v-model="dialogBatchNo" persistent max-width="1200">
      <v-card class="card-shadow border-radius-xl">
        <v-card-text>
          <v-data-table :headers="headersBatchno" :items="batchNo" mobile-breakpoint="0" hide-default-footer fixed-header
            disable-pagination class="table">
            <template v-slot:top>
              <v-toolbar flat>
                <!-- <v-toolbar-title>Private Allocation</v-toolbar-title> -->

                <v-spacer></v-spacer>
                <v-btn @click="closeBatchDialogue" text elevation="0" :ripple="false" height="43" color="red">close
                </v-btn>
              </v-toolbar>
            </template>
          </v-data-table>
        </v-card-text>
      </v-card>
    </v-dialog>
    <!-- Show Selected List -->
    <v-dialog v-model="showSelectedList" persistent fullscreen transition="dialog-bottom-transition">
      <v-card class="card-shadow border-radius-xl">
        <v-card-title>
          <v-spacer></v-spacer>
          <v-btn @click="closeListDialogue" elevation="0" :ripple="false" height="43" dark class="
              font-weight-bold
              text-capitalize
              btn-ls btn-primary
              bg-success
              py-3
              px-6
              mb-5
              mt-5
              mr-3
            ">Go Back to Select More
          </v-btn>

          <v-btn @click="confirm" elevation="0" :ripple="false" height="43" dark class="
              font-weight-bold
              text-capitalize
              btn-ls btn-primary
              bg-success
              py-3
              px-6
              mb-5
              mt-5
              mr-3
            ">Save
          </v-btn>
          <v-btn @click="saveDraft" elevation="0" :ripple="false" height="43" dark class="
              font-weight-bold
              text-capitalize
              btn-ls btn-primary
              bg-success
              py-3
              px-6
              mb-5
              mt-5
            ">Save as Draft
          </v-btn>
        </v-card-title>
        <v-card-text><v-row>
            <v-col cols="12" md="2">
              <label class="text-md font-weight-bolder ms-1 mandatory">
                Bill Date</label>
              <v-menu ref="mnu_date" v-model="mnu_date" :close-on-content-click="false" :return-value.sync="mnu_date"
                transition="scale-transition" offset-y min-width="auto">
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field v-model="date" readonly v-bind="attrs" v-on="on" hide-details="auto" class="
                      input-style
                      font-size-input
                      text-light-input
                      placeholder-light
                      input-icon
                      mt-5
                    " dense flat filled solo height="46" placeholder="Date" persistent-hint></v-text-field>
                </template>
                <v-date-picker color="green lighten-1" header-color="primary" v-model="date" no-title scrollable>
                  <v-spacer></v-spacer>
                  <v-btn text color="primary" @click="mnu_date = false">
                    Cancel
                  </v-btn>
                  <v-btn text color="primary" @click="$refs.mnu_date.save(date)">
                    OK
                  </v-btn>
                </v-date-picker>
              </v-menu> </v-col><v-col cols="12" md="2">
              <label class="text-md font-weight-bolder ms-1 mandatory">
                Mode Of Transport</label>
              <v-text-field v-model="mode_of_transport" hide-details="auto" class="
                  input-style
                  font-size-input
                  text-light-input
                  placeholder-light
                  input-icon
                  mt-5
                " dense flat filled solo height="43" placeholder="Enter mode of Transport"
                persistent-hint></v-text-field>
            </v-col>
            <v-col cols="12" md="2">
              <label class="text-md font-weight-bolder ms-1 mandatory">
                Vehicle No</label>
              <v-text-field hide-details="auto" class="
                  input-style
                  font-size-input
                  text-light-input
                  placeholder-light
                  input-icon
                  mt-5
                " dense flat filled solo height="43" v-model="vehicle_no" placeholder="Enter Vehicle Number"
                persistent-hint></v-text-field>
            </v-col>
            <v-col cols="12" md="2">
              <label class="text-md font-weight-bolder ms-1 mandatory">
                Driver Contact No</label>
              <v-text-field hide-details="auto" class="
                  input-style
                  font-size-input
                  text-light-input
                  placeholder-light
                  input-icon
                  mt-5
                " dense flat filled solo height="43" placeholder="Enter Conatct Number" v-model="driver_contact_no"
                persistent-hint></v-text-field> </v-col><v-col cols="12" md="3">
              <label class="text-md font-weight-bolder ms-1 mandatory">
                Remark</label>
              <v-text-field hide-details="auto" class="
                  input-style
                  font-size-input
                  text-light-input
                  placeholder-light
                  input-icon
                  mt-5
                " dense flat filled solo height="43" placeholder="Enter Remark" v-model="remarks"
                persistent-hint></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col offset="8">
              <label class="text-md font-weight-bolder ms-1 mandatory ml-2">
                Items Selected : {{ selectedData.length }}</label><br />
              <label class="text-md font-weight-bolder ms-1 mandatory">Total Quantity : {{ totalQty }}</label>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-text>
          <v-form ref="frm_table">
            <v-data-table v-model="saveList" :headers="showListHeader" :items="selectedList" item-key="id"
              mobile-breakpoint="0" fixed-header disable-pagination hide-default-footer show-select class="table"
              @item-selected="selectOne" @toggle-select-all="allSelectedList">
              <template v-slot:top>
                <v-toolbar flat>
                  <!-- <v-toolbar-title>Private Allocation</v-toolbar-title> -->
                </v-toolbar>
              </template>
              <template v-slot:[`item.ordered_no_of_packages`]="{ item }">
                <div>
                  <v-text-field v-model.number="item.ordered_no_of_packages" :disabled="item.no_of_packages == 0" class="
                      input-style
                      font-size-input
                      text-light-input
                      placeholder-light
                      input-icon
                      mt-5
                    " dense flat filled solo :rules="[
                      (v) => v > 0 || 'Min should be above 0',
                      (v) =>
                        v <= item.no_of_packages ||
                        `Max should not be above ${item.no_of_packages}`,
                    ]" :min="0" :max="item.no_of_packages" @change="calcVal(item)"></v-text-field>
                </div>
              </template><template v-slot:[`item.ordered_quantity`]="{ item }">
                <div>
                  <!-- If ordered_quantity >total_net_kgs then  ordered_quantity = total_net_kgs
                  
                    @keyup="
                      checkValues(item.total_net_kgs, item.ordered_quantity)
                    "
                    :v-on="
                      (item.ordered_quantity =
                        item.ordered_no_of_packages * item.net_kgs >
                        item.total_net_kgs
                          ? item.total_net_kgs
                          : item.ordered_no_of_packages * item.net_kgs)
                    "
                  
                  
                  -->
                  <v-text-field v-model.number="item.ordered_quantity" class="
                      input-style
                      font-size-input
                      text-light-input
                      placeholder-light
                      input-icon
                      mt-5
                    " dense flat filled solo min="0" :max="item.total_net_kgs" :rules="[
                      (v) => v > 0 || 'Min should be above 0',
                      (v) =>
                        v <= item.total_net_kgs ||
                        `Max should not be above ${item.total_net_kgs}`,
                    ]"></v-text-field>
                </div>
              </template>
            </v-data-table>
          </v-form>
        </v-card-text>
      </v-card>
    </v-dialog>
    <!-- Dialogue Remove Selected Confirm -->
    <v-dialog v-model="dialogueRemove" max-width="500px" persistent>
      <v-card class="card-shadow card-padding border-radius-xl">
        <v-card-title class="pt-0 text-h5 text-typo justify-center">
          <p class="text-center">Do you want to Remove this item?</p>
        </v-card-title>
        <v-card-actions class="pb-0">
          <v-spacer></v-spacer>
          <v-btn @click="cancelRemove" elevation="0" :ripple="false" height="43"
            class="font-weight-bold text-capitalize btn-ls bg-light py-3 px-6">No</v-btn>

          <v-btn @click="confirmRemove" elevation="0" :ripple="false" height="43" class="
              font-weight-bold
              text-capitalize
              btn-ls btn-primary
              bg-success
              py-3
              px-6
            ">Yes</v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import api from "../api";
import apiMaster from "../../../master/master-get-api";

export default {
  data() {
    return {
      // List of selected filters
      internal_transfer_ref: "",
      filter: {
        grade: [],
        mark: [],
        search: "",
      },
      // Bacth No Show
      dialogBatchNo: false,
      batchNo: [],
      headersBatchno: [
        {
          text: "Grade",
          value: "grade",
          class: "text-secondary font-weight-bolder opacity-10",
          align: "left",
          width: 100,
        },
        {
          text: "Mark",
          value: "mark_name",
          class: "text-secondary font-weight-bolder opacity-10",
          align: "left",
          width: 100,
        },
        {
          text: "Invoice",
          value: "invoice_number",
          class: "text-secondary font-weight-bolder opacity-10",
          align: "left",
          width: 100,
        },
        {
          text: "Item Name",
          value: "item_name",
          class: "text-secondary font-weight-bolder opacity-10",
          align: "left",
          width: 100,
        },
        {
          text: "Batch No",
          value: "batch_no",
          class: "text-secondary font-weight-bolder opacity-10",
          align: "left",
          width: 250,
        },
      ],
      //
      driver_contact_no: "",
      vehicle_no: "",
      mode_of_transport: "",
      remarks: "",
      grade: [],
      mark: [],
      items: [],
      overlay: false,
      showInventory: false,
      from_company_warehouse: null,
      from_company_warehouses: [],
      to_company_warehouse: null,
      to_company_warehouses: [],
      all_company_warehouses: [],
      selectedData: [],
      selectedList: [],
      saveList: [],
      inventoryDetails: [],
      vendors: [],
      vendor: null,
      headers: [
        // {
        //   text: "Batch No",
        //   value: "batch_no",
        //   class: "text-secondary font-weight-bolder opacity-10",
        //   align: "left",
        //   width: 100,
        //   sortable: true,
        //   filterable: true,
        // },
        {
          text: "Garden",
          value: "mark_name",
          class: "text-secondary font-weight-bolder opacity-10",
          align: "left",
          width: 100,
          sortable: true,
          filterable: false,
        },
        {
          text: "Grade",
          value: "grade",
          class: "text-secondary font-weight-bolder opacity-10",
          align: "left",
          width: 75,
          sortable: true,
          filterable: false,
        },
        {
          text: "Invoice NO",
          value: "invoice_number",
          class: "text-secondary font-weight-bolder opacity-10",
          align: "left",
          width: 100,
          sortable: false,
          filterable: true,
        },

        {
          text: "Item Name",
          value: "item_name",
          class: "text-secondary font-weight-bolder opacity-10",
          align: "left",
          width: 100,
          sortable: true,
          filterable: true,
        },
        {
          text: "Available (Bags)",
          value: "no_of_packages",
          class: "text-secondary font-weight-bolder opacity-10",
          align: "left",
          width: 75,
          sortable: false,
          filterable: false,
        },
        {
          text: "Net Kgs ",
          value: "net_kgs",
          class: "text-secondary font-weight-bolder opacity-10",
          align: "left",
          width: 75,
          sortable: false,
          filterable: false,
        },
        {
          text: "Available (Kgs)",
          value: "total_net_kgs",
          class: "text-secondary font-weight-bolder opacity-10",
          align: "left",
          width: 75,
          sortable: false,
          filterable: false,
        },
        {
          text: "purchased rate",
          value: "purchased_rate",
          class: "text-secondary font-weight-bolder opacity-7",
          align: "left",
          width: 75,
          sortable: false,
          filterable: false,
        },
      ],
      headerList: [
        {
          text: "Order (Bags)",
          value: "ordered_no_of_packages",
          class: "text-secondary font-weight-bolder opacity-10",
          align: "left",

          width: 150,




          sortable: false,
          filterable: false,
        },
        {
          text: "Order (Kgs)",
          value: "ordered_quantity",
          class: "text-secondary font-weight-bolder opacity-10",
          align: "left",


          width: 150,




          sortable: false,
          filterable: false,
        },
      ],
      showListHeader: [],

      //
      gstTypes: [
        {
          type: "GST",
        },
        { type: "IGST" },
      ],
      gstType: "IGST",
      showLgst: false,
      gst: {
        cgst: 0,
        id: 0,

        igst: 0,
        sgst: 0,
      },
      isDisable: true,
      confirmDialog: false,
      total_qty: 0,
      date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
      mnu_date: false,
      showSelectedList: false,
      dialogueRemove: false,
      removeObj: null,

      showLgst: false,
      showEdit: true,
      showIgst: true,
    };
  },
  created() {
    // console.log("THIS>DATE", this.date);
    this.initialize();
  },
  methods: {
    async initialize() {
      this.overlay = true;
      this.internal_transfer_ref = await api.getRefNo();
      this.grade = await apiMaster.getGrade();
      this.mark = await apiMaster.getMark();

      this.vendors = await api.getOwnVendors();
      this.all_company_warehouses = await api.getCompanyWareHouse();
      this.from_company_warehouses = await api.getCompanyWareHouse();

      let result = await api.getGst();
      this.gst.cgst = result[0].cgst;
      this.gst.sgst = result[0].sgst;
      this.gst.igst = result[0].igst;

      if (this.gst.cgst > 0 || this.gst.sgst > 0) {
        this.gstType = "GST";
        this.showLgst = true;
        this.showIgst = false;
      }
      if (this.gst.igst > 0) {
        this.gstType = "IGST";
        this.showIgst = true;
        this.showLgst = false;
      }
      this.selectedData = [];
      this.selectedList = [];
      this.saveList = [];
      this.gst.id = result[0].id;

      console.log(this.gst);

      this.overlay = false;
    },
    async getWareHouse() {
      this.from_company_warehouses = await api.getCompanyWareHouseByVendor(
        this.vendor
      );
    },

    filterCompanyWareHouse() {
      this.to_company_warehouses = [...this.all_company_warehouses];
      this.to_company_warehouses.splice(
        this.to_company_warehouses.findIndex(
          (el) => el.id == this.from_company_warehouse
        ),
        1
      );
    },

    async getInventory() {
      this.$refs.frmFilter.reset();
      if (this.validateFilter()) {
        this.overlay = true;
        this.selectedData = [];
        this.saveList = [];
        this.inventoryDetails = await api.getInventory(
          this.from_company_warehouse
        );
        if (this.inventoryDetails.length > 0) {
          this.showInventory = true;
        } else {
          this.nodDataAlert("No Data Found...");
        }
        this.items = this.inventoryDetails;
        this.overlay = false;
      }
    },
    toggleGrade() {
      this.$nextTick(() => {
        if (this.selectAll) {
          this.filter.grade = [];
          this.inventoryDetails = this.items;
        } else {
          this.filter.grade = this.grade.slice();
          this.inventoryDetails = this.items;
        }
      });
    },
    toggleMark() {
      this.$nextTick(() => {
        if (this.selectAll) {
          this.filter.mark = [];
        } else {
          this.filter.mark = this.mark.slice();
        }
      });
    },
    validateFilter() {
      return this.$refs.frm.validate();
    },
    tableValidation() {
      return this.$refs.frm_table.validate();
    },
    async filterByGradeMark() {
      this.inventoryDetails = this.items;
      if (this.filter.grade.length > 0) {
        this.inventoryDetails = this.inventoryDetails.filter((item) => {
          return this.filter.grade.includes(item.grade);
        });
      }
      if (this.filter.mark.length > 0) {
        this.inventoryDetails = this.inventoryDetails.filter((item) => {
          return this.filter.mark.includes(item.mark_name);
        });
      }
    },

    clearFilter() {
      this.vendor = null;
      this.selectedData = [];
      this.saveList = [];

      this.from_company_warehouse = null;
      // this.from_company_warehouses = [];
      this.to_company_warehouse = null;
      this.to_company_warehouses = [];
      this.showInventory = false;
      this.inventoryDetails = [];
      this.$refs.frm.resetValidation();
      this.$refs.frmFilter.reset();
    },
    confirm() {
      if (this.tableValidation()) {
        if (this.validateFilter()) {
          // this.dialog = false;
          this.showSelectedList = false;
          if (this.isDisable == true) {
            this.message = "";
            if (this.gstType == "GST") {
              this.message = ` SGST ${this.gst.sgst} % , CGST ${this.gst.cgst} %`;
            }
            if (this.gstType == "IGST") {
              this.message = ` IGST ${this.gst.igst} % `;
            }
            this.confirmDialog = true;
          } else {
            this.showWarningAlert("Please Save GST First");
          }
        }
      }
    },
    async confirmOk() {
      // this.overlay = true;
      this.confirmDialog = false;
      await this.save();
      // this.overlay = false;
    },
    confirmCancel() {
      this.confirmDialog = false;
      this.removeObj = null;
    },

    ShowSelected() {
      if (this.selectedData.length > 0) {
        this.showSelectedList = true;
        this.showListHeader = [];
        this.showListHeader = this.headers.concat(this.headerList);
        // this.saveList = this.selectedData;
      } else {
        this.showNoDataAlert("Please Select atleast one row");
      }
    },
    closeListDialogue() {
      this.showSelectedList = false;
    },

    // ## Save As draft

    async saveDraft() {
      let child = [];
      this.saveList.forEach((el) => {
        let r = {
          ordered_no_of_packages: el.ordered_no_of_packages, //ordered

          ordered_quantity: el.ordered_quantity, //Ordered

          inventory_id: el.id,
        };
        child.push(r);
      });
      let req = {
        from_company_ware_house: this.from_company_warehouse, //From

        company_warehouse: this.to_company_warehouse, //To

        sale_date: this.date,

        driver_contact_no: this.driver_contact_no,

        mode_of_transport: this.mode_of_transport,

        vehicle_no: this.vehicle_no,

        remarks: this.remarks,
        child,
      };
      console.log("this.saveList", this.saveList, "req", req);
      try {
        await api.saveAsDraft(req).then((res) => {
          this.showSuccessAlert("Draft Saved...");
          this.saveList = [];
          this.selectedList = [];
          this.selectedData = [];
          this.showSelectedList = false;
        });
      } catch (error) { }
    },
    // ## Saving Data
    async save() {
      try {
        // if (this.validateFilter()) {
        if (this.saveList.length > 0) {
          this.overlay = true;
          let master = {
            sale_date: this.date,
            // vendor: this.vendors[0],
            purchase_type: "INTERNAL TRANSFER",

            // From WareHouse as Vendor
            from_company_ware_house: this.from_company_warehouse,
            // To wareHouse
            company_warehouse: this.to_company_warehouse,
            gstType: this.gstType,
            po_number: "",
          };
          let req = {
            driver_contact_no: this.driver_contact_no,
            mode_of_transport: this.mode_of_transport,
            vehicle_no: this.vehicle_no,
            remarks: this.remarks,
            master: master,
            child: this.saveList,
            internal_transfer_ref: this.internal_transfer_ref,
          };

          // console.log(req);
          let r = await api.saveData(req);
          this.dialogBatchNo = true;
          this.batchNo = r;
          this.showSuccessAlert("Data Saved");
          this.inventoryDetails = this.inventoryDetails.filter(
            (el) => !this.selectedData.includes(el)
          );
          this.closeListDialogue();
          this.inventoryDetails = [];
          this.selectedData = [];
          this.initialize();
          this.overlay = false;
        } else {
          this.showWarningAlert("Select Atleast One Row");
        }
        // }
      } catch (error) {
        this.showErrorAlert(error);
        //  this.showSelectedList = true;
        this.internal_transfer_ref = await api.getRefNo();
        this.overlay = false;
        this.inventoryDetails = [];
        this.selectedData = [];
      }
    },
    closeBatchDialogue() {
      this.dialogBatchNo = false;
      this.batchNo = [];
    },
    showSuccessAlert(message) {
      this.$swal({
        title: "Success",
        showConfirmButton: false,
        text: message,
        type: "success",
        timer: 3000,
        icon: "success",
        showConfirmButton: false,
      });
    },

    showErrorAlert(message) {
      this.$swal({
        title: "Error",
        text: message,

        type: "error",

        icon: "error",
        showConfirmButton: true,
      });
    },
    nodDataAlert(message) {
      this.$swal({
        text: message,
        showConfirmButton: false,

        timer: 3000,
      });
    },
    showWarningAlert(message) {
      this.$swal({
        title: "warning",
        text: message,
        showConfirmButton: false,
        type: "warning",
        timer: 3000,
        icon: "warning",
      });
    },

    showNoDataAlert(message) {
      this.$swal({
        text: message,
        type: "warning",
        timer: 3000,
      });
    },

    typeChange() {
      console.log(this.gstType);
      if (this.gstType == "GST") {
        this.showLgst = true;
        this.showEdit = true;
        this.showIgst = false;
      }
      if (this.gstType == "IGST") {
        this.showLgst = false;
        this.showEdit = true;
        this.showIgst = true;
      }
    },
    async allSelected(obj) {
      console.log("SELECT a;", obj);
      if (obj.value == true) {
        this.saveList = obj.items;
        this.selectedList = obj.items;
      }
      if (obj.value == false) {
        this.saveList = [];
        this.selectedList = [];
      }
    },
    async allSelectedList(obj) {
      if (obj.value == false) {
        obj.value = true;
      }
    },
    selectOne(obj) {
      this.dialogueRemove = true;
      this.removeObj = obj;
    },
    async selected(obj) {
      if (obj.value == true) {
        this.saveList.push(obj.item);
        this.selectedList.push(obj.item);
      }
      if (obj.value == false) {
        this.saveList.splice(
          this.saveList.findIndex((el) => el.id == obj.item.id),
          1
        );
        this.selectedList.splice(
          this.selectedList.findIndex((el) => el.id == obj.item.id),
          1
        );
      }
    },
    confirmRemove() {
      let r = this.selectedData.findIndex((el) => {
        return el.id == this.removeObj.item.id;
      });
      this.selectedData.splice(r, 1);

      this.selectedList.splice(
        this.selectedList.findIndex((el) => el.id == this.removeObj.item.id),
        1
      );

      this.removeObj = null;
      this.dialogueRemove = false;
    },
    cancelRemove() {
      this.removeObj = null;
      this.dialogueRemove = false;
      this.saveList = this.selectedList;
    },
    enableEdit() {
      this.isDisable = false;
    },
    async saveGst() {
      try {
        this.overlay = true;
        await api.saveGst(this.gst);
        this.showSuccessAlert("GST Saved");
        this.isDisable = true;
        this.overlay = false;
      } catch (error) {
        this.showErrorAlert(error);
      }
    },
    checkValues(total, edited) {
      console.log("total,edited", total, edited);
    },
    calcVal(item) {
      item.ordered_quantity = item.ordered_no_of_packages * item.net_kgs;
      if (item.ordered_quantity > item.total_net_kgs) {
        item.ordered_quantity = item.total_net_kgs;
      }
    },
  },
  computed: {
    totalQty() {
      let qty = 0;
      this.saveList.forEach((el) => {
        qty = parseFloat(el.ordered_quantity) + parseFloat(qty);
      });
      return qty;
    },
  },
};
</script>

<style>
/* .v-overlay {
  z-index: 1001 !important;
} */
</style>
